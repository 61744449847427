import { ClientFilter } from 'src/@types/filters';
import { Role } from 'src/@types/roles';

export const ROLE = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  AGENT: 'agent',
  PLAYER: 'player',
} as const;

export const ACCESS_LEVELS = {
  [ROLE.ADMIN]: '4',
  [ROLE.MANAGER]: '3',
  [ROLE.AGENT]: '2',
  [ROLE.PLAYER]: '1',
} as const;

export const ROLES_BY_ACCESS_LEVEL: Record<number, Role> = {
  4: ROLE.ADMIN,
  3: ROLE.MANAGER,
  2: ROLE.AGENT,
  1: ROLE.PLAYER,
} as const;

export const DEFAULT_ROLE_OPTIONS = {
  4: ROLE.MANAGER,
  3: ROLE.AGENT,
  2: ROLE.PLAYER,
} as const;

export const INITIAL_FILTERS: ClientFilter = {
  manager: [],
  agent: [],
  player: [],
};

export const getAdditionalRoleOptions = (accessLevel: number) => {
  const FILTERABLE_ROLES_BY_ROLE: Partial<Record<Role, Role[]>> = {
    [ROLE.MANAGER]: [ROLE.MANAGER, ROLE.AGENT, ROLE.PLAYER],
    [ROLE.AGENT]: [ROLE.AGENT, ROLE.PLAYER],
    [ROLE.PLAYER]: [ROLE.PLAYER],
  };
  if (accessLevel < 4) {
    FILTERABLE_ROLES_BY_ROLE[ROLE.MANAGER] = [];
  }
  if (accessLevel < 3) {
    FILTERABLE_ROLES_BY_ROLE[ROLE.AGENT] = [];
  }
  return FILTERABLE_ROLES_BY_ROLE;
};
