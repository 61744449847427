import { createSlice } from '@reduxjs/toolkit';
import { BetReturnData } from 'src/@types/bets';
import { BetFilterTypes } from 'src/@types/filters';
import { SortData } from 'src/hooks/useTableSort';
import { post } from 'src/utils/api';
import { GET_BETS } from 'src/utils/constants/routeConstants';
import { dispatch } from '../store';

type BetState = {
  isLoading: boolean;
  error: Error | string | null;
  bets: BetReturnData | null;
};

const initialState: BetState = {
  isLoading: false,
  error: null,
  bets: null,
};

const slice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getBetsSuccess(state, action) {
      state.isLoading = false;
      state.bets = action.payload;
    },
  },
});

export default slice.reducer;

export function getBets(page: number, rowsPerPage: number, filters: BetFilterTypes, sort: SortData) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const data = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        userId: filters.userId,
        entityId: filters.entityId,
        username: filters.username,
        roundId: filters.roundId,
        providerName: filters.providerName,
        gameId: filters.gameId,
        payInAmount: filters.payInAmount,
        payOutAmount: filters.payOutAmount,
        percentageGain: filters.percentageGain / 100,
        betStatus: filters.betStatus,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
      };

      const sortQueryParam = sort.column && sort.order ? `&${sort.column}=${sort.order}` : '';

      const response = await post(`${GET_BETS}?${sortQueryParam}`, data);

      dispatch(slice.actions.getBetsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
