import { createSlice } from '@reduxjs/toolkit';
import { Marketplace } from 'src/@types/marketplace';
import { dispatch } from '../store';

type MarketplaceState = {
  isLoading: boolean;
  error: Error | string | null;
  marketplace: Marketplace | null;
};

export type UpdateMarketplaceData = {
  newMarketplaceData: Marketplace;
};

const initialState: MarketplaceState = {
  isLoading: false,
  error: null,
  marketplace: null,
};

const slice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE MARKETPLACE
    updateMarketplaceSuccess(state, action) {
      state.marketplace = action.payload;
    },
  },
});

export default slice.reducer;

export function updateMarkeplace(marketplaceData: Marketplace) {
  dispatch(slice.actions.updateMarketplaceSuccess(marketplaceData));
}
