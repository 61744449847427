import axios from 'axios';
import { Currency } from 'src/@types/currency';
import { TransactionsData, TransactionsReturnData } from 'src/@types/transactions';
import { GameData, GameEdit, GameReturnData } from 'src/@types/games';
import { LogInData, AuthenticateData, User, UserEdit } from 'src/@types/marketplaceUser';
import { ProviderReturnData, ProviderData } from 'src/@types/provider';
import { Entity, GetUsersData } from 'src/@types/users';
import { API_URL } from 'src/config';
import { CategoryEditData, CategoryReturnData, CategoryCreateData } from 'src/@types/category';
import { BetData, BetReturnData } from 'src/@types/bets';
import { SummaryData, SummaryRequestData } from 'src/@types/summary';
import { UpdateMarketplaceData } from 'src/redux/slices/marketplace';
import { MarketplaceEditValues } from 'src/@types/marketplace';
import { Banner } from 'src/@types/banner';
import {
  UserStatisticsData,
  UserStatisticsReturnData,
  RevenueData,
  RevenueStatisticsReturnData,
} from 'src/@types/statistics';
import { FrbData } from 'src/@types/frb';

const createConfig = (formData = false): any => {
  const url = window.location !== window.parent.location ? document.referrer : document.location.href;
  const token = window?.localStorage?.getItem('accessToken')?.replace(/["']/g, '');
  return {
    headers: {
      authorization: `Bearer ${token && token}`,
      Source: url,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': formData ? 'multipart/form-data' : 'application/json',
    },
  };
};

export const get = async (
  route: string
): Promise<ProviderReturnData | CategoryReturnData | Currency[] | Entity[] | string[] | Banner[]> => {
  const config = createConfig();
  let returnData = null;
  try {
    returnData = await axios.get(API_URL + route, config);
  } catch (error) {
    console.error(error);
  }
  return returnData?.data;
};

export const post = async (
  route: string,
  data:
    | GameData
    | LogInData
    | AuthenticateData
    | GetUsersData
    | TransactionsData
    | CategoryCreateData
    | ProviderData
    | SummaryRequestData
    | UserStatisticsData
    | BetData
    | RevenueData
    | FrbData,
  formData = false
): Promise<
  | GameReturnData
  | User
  | User[]
  | TransactionsReturnData
  | ProviderReturnData
  | SummaryData[]
  | UserStatisticsReturnData
  | BetReturnData
  | RevenueStatisticsReturnData
> => {
  const config = createConfig(formData);
  let returnData = null;
  try {
    returnData = await axios.post(API_URL + route, data, config);
  } catch (error) {
    console.error(error);
  }
  return returnData?.data;
};

export const patch = async (
  route: string,
  data: MarketplaceEditValues | GameEdit | CategoryEditData | UserEdit,
  formData = false
): Promise<UpdateMarketplaceData> => {
  const config = createConfig(formData);
  let returnData = null;
  try {
    returnData = await axios.patch(API_URL + route, data, config);
  } catch (error) {
    console.error(error);
  }
  return returnData?.data;
};

export const axiosDelete = async (route: string): Promise<Banner> => {
  const config = createConfig();
  let returnData = null;
  try {
    returnData = await axios.delete(API_URL + route, config);
  } catch (error) {
    console.error(error);
  }
  return returnData?.data;
};
