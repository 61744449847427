export const AUTHENTICATE = '/auth/authenticate';
export const LOG_IN = '/auth/login';
export const GET_GAMES = '/games';
export const GET_PROVIDERS = '/provider/providers';
export const GET_CATEGORIES = '/category/categories';
export const GET_TRANSACTIONS = '/transaction/transactions';
export const EDIT_MARKETPLACE = '/marketplace/update';
export const GET_USERS = '/user/users';
export const CREATE_USERS = '/user/create';
export const GET_ENTITIES = '/data/entities';
export const GET_CURRENCIES = '/currencies';
export const UPDATE_GAME = '/game';
export const UPDATE_INFO = '/user';
export const CREATE_CATEGORY = '/category/create';
export const UPDATE_CATEGORY = '/category';
export const GET_SUMMARY = '/transaction/summary';
export const CREATE_BANNER = '/banner/create';
export const DELETE_BANNER = '/banner';
export const GET_BANNERS = '/banner/banners';
export const USER_STATISTICS = '/statistics/users';
export const REVENUE_STATISTICS = '/statistics/revenue';
export const GET_BETS = '/bet/bets';
export const CREATE_FRB = '/frb/create';
