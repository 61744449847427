import { createSlice } from '@reduxjs/toolkit';
import { Category, CategoryReturnData } from 'src/@types/category';
import { get } from 'src/utils/api';
import { GET_CATEGORIES } from 'src/utils/constants/routeConstants';
import { dispatch } from '../store';

type CategoryState = {
  isLoading: boolean;
  error: Error | string | null;
  categories: Category[] | [];
};

const initialState: CategoryState = {
  isLoading: false,
  error: null,
  categories: [],
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },
  },
});

export default slice.reducer;

export function getCategories() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = (await get(`${GET_CATEGORIES}`)) as CategoryReturnData;
      dispatch(slice.actions.getCategoriesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCategories(categoryList: Category[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCategoriesSuccess(categoryList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
