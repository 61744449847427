import { createSlice } from '@reduxjs/toolkit';
import { GameFilterTypes } from 'src/@types/filters';
import { GameReturnData } from 'src/@types/games';
import { post } from 'src/utils/api';
import { GET_GAMES } from 'src/utils/constants/routeConstants';
import { dispatch } from '../store';

type GameState = {
  isLoading: boolean;
  error: Error | string | null;
  games: GameReturnData;
  selectedGames: string[];
};

const initialState: GameState = {
  isLoading: false,
  error: null,
  games: {} as GameReturnData,
  selectedGames: [],
};

const slice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getGamesSuccess(state, action) {
      state.isLoading = false;
      state.games = action.payload;
    },
    setSelectedGamesSuccess(state, action) {
      state.selectedGames = action.payload;
    },
  },
});

export default slice.reducer;

export function getGames(page: number, rowsPerPage: number, filters: GameFilterTypes) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        name: filters.name,
        categoryId: filters.category !== 'all' ? filters.category : '',
        providerName: filters.provider !== 'all' ? filters.provider : '',
      };
      const response = (await post(`${GET_GAMES}`, data)) as GameReturnData;
      dispatch(slice.actions.getGamesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedGames(selectedGames: string[]) {
  return () => {
    dispatch(slice.actions.setSelectedGamesSuccess(selectedGames));
  };
}
